import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";
import {DigitalProduct} from "../types/digital-product.interface";

class DigitalProductApiService extends ApiService {

    public async getOwnerDigitalProducts(userId: string): Promise<DigitalProduct[]> {
        return await this.get<DigitalProduct[]>(`/personal-experience/owner/${userId}`)
            .catch((error) => {
                NotificationService.error("Error loading digital products");
                throw error;
            });
    }

    public async buyDigitalProduct(productId: number): Promise<void> {
        return await this.post<void>(`/personal-experience/buy/${productId}`, {}, {
            successMessage: "Purchase completed successfully! Your digital product is now available."
        })
            .catch((error) => {
                throw error;
            });
    }
}

export default new DigitalProductApiService()
