import React, { useState } from 'react';
import { useFormContext } from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

interface PasswordFormFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
    name: string;
    label: string;
    className?: string;
    isLoading?: boolean;
    showPasswordToggle?: boolean;
}

const PasswordFormField: React.FC<PasswordFormFieldProps> = (props: PasswordFormFieldProps) => {
    const { register, formState: { errors, isSubmitting } } = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        showPasswordToggle = true,
        ...rest
    } = props;

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='mb-4'>
            <div className={`relative w-full h-fit`}>
                <label htmlFor={name} className="absolute block text-xs font-medium top-2 left-[14px]">
                    {label}
                </label>
                {isLoading
                    ? <SkeletonFormField />
                    : <>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id={name}
                            {...register(name)}
                            placeholder="Write"
                            className={`border-2 border-gray-light rounded-xl px-3 py-2 pr-10 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary ${errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400'} ${className}`}
                            disabled={isSubmitting}
                            {...rest}
                        />
                        {showPasswordToggle && (
                            <div
                                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <EyeSlashIcon
                                        className={`h-5 w-5 ${errors[name] ? 'text-red-500' : 'text-gray-500'}`}
                                    />
                                ) : (
                                    <EyeIcon
                                        className={`h-5 w-5 ${errors[name] ? 'text-red-500' : 'text-gray-500'}`}
                                    />
                                )}
                            </div>
                        )}
                    </>
                }
            </div>
            {errors[name] && <p className="text-red-500 text-xs mt-1">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default PasswordFormField;
