import React, {ReactNode} from 'react';
import AppHamburgerMenu from "./components/app-hamburger-menu";
import {useLocation} from 'react-router-dom';
import headerBackground from '../assets/images/header-background.png';
import AppContentWrapper from "./components/app-content-wrapper";
import {useMediaQuery} from "react-responsive";
import useAuth from "../features/auth/hooks/use-auth";

interface HeaderLayerProps {
    children?: ReactNode;
}

const generateBreadcrumbs = (path: string): ReactNode => {
    const pathParts = path.split('/').filter(part => part); // Filtrujemy puste fragmenty
    return pathParts.map((part, index) => {
        const isLast = index === pathParts.length - 1;
        return (
            <span key={index} className={isLast ? 'font-bold' : ''}>
                {part.charAt(0).toUpperCase() + part.slice(1)}
                {!isLast && ' / '}
            </span>
        );
    });
};

const HeaderLayer: React.FC<HeaderLayerProps> = ({children}) => {
    const location = useLocation();
    const {currentUser} = useAuth();
    const isMobile = useMediaQuery({maxWidth: '640px'})

    return (
        <div
            style={!isMobile ? ({background: `no-repeat center/100% url(${headerBackground})`}) : ({background:'transparent'})}
            className="relative flex flex-col gap-3 bg-white px-3 py-4 bg-cover bg-center lg:rounded-3xl"
        >
            {isMobile && (
                <div className='absolute top-0 left-0 w-full h-[calc(100%+50px)]' style={{background: `url(${headerBackground})`}}>

                </div>
            )}
            <AppContentWrapper>
                <div className='flex items-center gap-3'>
                    <AppHamburgerMenu/>
                    <div className='flex items-center text-gray-500 text-primary-darken font-bold text-2xl'>
                        {generateBreadcrumbs(location.pathname)}
                        {location.pathname === '/' && `Hi, ${currentUser?.firstname}`}
                    </div>
                    {/*<img src={require('../assets/images/main-logo.png')} alt='logo-yurs' className='w-28'/>*/}
                </div>
                <div className='flex flex-col justify-between items-center'>
                    {children && (
                        <div className='w-full py-5'>
                            {children}
                        </div>
                    )}
                </div>
            </AppContentWrapper>
        </div>
    );
};

export default HeaderLayer;
