import React from "react";
import {useMediaQuery} from "react-responsive";
import {useSidebar} from "../hooks/use-sidebar";
import {SidebarIds} from "../types/sidebar-ids.interface";
import {Bars3Icon} from "@heroicons/react/24/outline";

const AppHamburgerMenu = () => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const { openSidebar } = useSidebar();

    return (
        <div>
            {!isDesktop && (
                <button
                    onClick={() => openSidebar(SidebarIds.MENU)}
                    className="flex items-center justify-center w-10 h-10 p-2 bg-primary-pastel rounded-2xl hover:bg-gray-100 focus:outline-none"
                >
                    <Bars3Icon/>
                </button>
            )}
        </div>
    );
}

export default AppHamburgerMenu;
