import React from 'react';
import ForgotPasswordForm from "../../components/forgot-password-form";

interface ForgotPasswordViewProps {
    onSubmit: (emailOrUsername: string) => Promise<void>
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = (props: ForgotPasswordViewProps) => {
    const {onSubmit} = props;

    return (
        <div className="flex flex-col h-full bg-gray-100">
            <div className="flex flex-col flex-grow gap-y-3 bg-white rounded w-full justify-center">
                <p className="mb-4 text-sm text-gray">
                    Forgot your password? No worries! Our password recovery
                    feature makes it simple to regain access to your account
                    in just a few easy steps.
                </p>
                <ForgotPasswordForm onSubmit={onSubmit}/>
            </div>
        </div>
    );
};

export default ForgotPasswordView;
