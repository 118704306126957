import {useState} from 'react';
import DigitalProductApiService from "../services/digital-product-api.service";
import {DigitalProduct} from "../types/digital-product.interface";

export const useDigitalProductApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getOwnerDigitalProducts = async (userId: string): Promise<DigitalProduct[]> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.getOwnerDigitalProducts(userId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const buyDigitalProduct = async (productId: number): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductApiService.buyDigitalProduct(productId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        buyDigitalProduct,
        getOwnerDigitalProducts,
    };
};
