import React, {Fragment, useEffect, useState} from 'react';
import {Transition} from '@headlessui/react';
import notificationService from '../../services/notification.service';
import {ListenerNotify, Notification, Notifications} from '../../types/notification.interface';
import SuccessNotification from './success-notification';
import ErrorNotification from './error-notification';
import WarningNotification from './warning-notification';
import InfoNotification from './info-notification';
import {useMediaQuery} from "react-responsive";
import {classNames} from "../../../../utils/class-names";

const NotificationPrompt: React.FC = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const isMobile = useMediaQuery({maxWidth: 640});

    useEffect(() => {
        const handleNotifications: ListenerNotify = (notifications: Notification[]): void => {
            setNotifications([...notifications]);
        };

        notificationService.addListener(handleNotifications);

        return (): void => {
            notificationService.removeListener(handleNotifications);
        };
    }, []);

    const closeNotification = (notification: Notification): void => {
        notificationService.remove(notification);
    };

    const renderNotificationComponent = (notification: Notification) => {
        switch (notification.type) {
            case Notifications.SUCCESS:
                return <SuccessNotification notification={notification}
                                            onClose={() => closeNotification(notification)}/>;
            case Notifications.ERROR:
                return <ErrorNotification notification={notification} onClose={() => closeNotification(notification)}/>;
            case Notifications.WARNING:
                return <WarningNotification notification={notification}
                                            onClose={() => closeNotification(notification)}/>;
            case Notifications.INFO:
            default:
                return <InfoNotification notification={notification} onClose={() => closeNotification(notification)}/>;
        }
    };

    return (
        <div className={classNames(
            'fixed space-y-2 sm:w-96 w-full z-50',
            isMobile ? 'bottom-16 left-0 px-2' : 'top-4 right-4'
        )}>
            {notifications.map((notification: Notification) => (
                <Transition
                    key={notification.id}
                    show={true}
                    as={Fragment}
                    appear={true}
                    enter="transform transition duration-500"
                    enterFrom="opacity-0 translate-y-4 translate-x-4"
                    enterTo="opacity-100 translate-y-0 translate-x-0"
                    leave="transform transition duration-500"
                    leaveFrom="opacity-100 translate-y-0 translate-x-0"
                    leaveTo="opacity-0 translate-y-4 translate-x-4"
                >
                    <div>{renderNotificationComponent(notification)}</div>
                </Transition>
            ))}
        </div>
    );
};

export default NotificationPrompt;
