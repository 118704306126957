import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import AppButton from "../../common/components/app-button";
import NumberFormField from "../../common/components/forms/number-form-field";
import useAuth from "../hooks/use-auth";
import PasswordFormField from "../../common/components/forms/password-form-field";
import {ResetPasswordFormBody} from "../types/reset-email-body.interface";

const schema = yup.object({
    token: yup
        .string()
        .required("Code is required"),
    newPassword: yup
        .string()
        .required("Password is required")
        .min(8, 'Password must be at least 8 characters long')
        .max(255, 'Password must not exceed 255 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one digit')
        .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match')
        .required("Confirming password is required"),
}).required();

interface SendTokenResetPasswordFormProps {
    onSubmit: (token: string, newPassword: string) => Promise<void>;
}

const SendTokenResetPasswordForm = ({onSubmit}: SendTokenResetPasswordFormProps) => {
    const {loading} = useAuth();

    const methods = useForm<ResetPasswordFormBody>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        delayError: 100,
    });

    const handleCodeSubmit = (data: ResetPasswordFormBody) => {
        onSubmit(data.token, data.newPassword)
            .then()
            .catch(() => {
            })
            .finally();
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleCodeSubmit)}
                  className="flex flex-col justify-between gap-y-5 flex-grow">
                <div>
                    <NumberFormField maxLength={6} name="token" label="Verification code" placeholder="Write"/>
                    <PasswordFormField name='newPassword' label='New password' placeholder='Write new password...'/>
                    <PasswordFormField name='confirmPassword' label='Repeat new password'
                                       placeholder='Repeat new password...'/>
                    {/*<p className="text-gray text-xs font-medium flex gap-1 items-center">*/}
                    {/*    <InformationCircleIcon className='w-4 h-4 cursor-pointer'/>*/}
                    {/*    Didn’t receive a code? <span className='underline cursor-pointer'>Resend a code (47)</span>*/}
                    {/*</p>*/}
                </div>
                <AppButton
                    type="submit"
                    loading={loading}
                    label="Reset password"
                    className="w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary"
                />
            </form>
        </FormProvider>
    );
};

export default SendTokenResetPasswordForm;
