import {useRoutes} from 'react-router-dom';
import AppLandingPage from "./pages/app-landing-page";
import MainLayer from "./layout/app-main";
import GuardAuthRoutes from "./features/auth/components/GuardAuthRoutes";
import GuardPrivateRoutes from "./features/auth/components/GuardPrivateRoutes";
import LoginPage from "./pages/login-page";
import RegisterPage from "./pages/register-page";
import ForgotPasswordPage from "./pages/forgot-password-page";
import NotFoundPage from "./pages/not-found-page";
import AppPublicProfilePage from "./pages/app-public-profile-page";

const AppRoutes = () => {
    return useRoutes(
        [
            {
                path: "/profile/:username",
                element: <AppPublicProfilePage/>
            },
            {
                path: '/auth',
                element: <GuardAuthRoutes/>,
                children: [
                    {path: '', element: <AppLandingPage/>},
                    {path: 'login', element: <LoginPage/>},
                    {path: 'register', element: <RegisterPage/>},
                    {path: 'forgot-password', element: <ForgotPasswordPage/>},
                ],
            },
            {
                path: '/*',
                element: <GuardPrivateRoutes/>,
                children: [
                    {path: '*', element: <MainLayer/>},
                ],
            },
            {path: '/not-found', element: <NotFoundPage/>},
        ]
    )
};

export default AppRoutes;
