import WrapperCard from "../../common/components/wrapper-card";
import {classNames} from "../../../utils/class-names";
import {DigitalProduct} from "../../core/types/digital-product.interface";
import Coin from "../../core/components/coin";
import {PhotoIcon} from "@heroicons/react/24/solid";
import AppButton from "../../common/components/app-button";
import React from "react";
import DigitalProductCategoryService from "../../profile/services/digital-product-category.service";
import {useModal} from "../../common/hooks/use-modal";
import {ModalIds} from "../../common/types/modal-ids.interface";
import useRequiredFollow from "../../core/hooks/use-required-follow";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import useModalWithData from "../../common/hooks/use-modal-with-data";

interface PreviewDigitalProductProps {
    product: DigitalProduct;
    creatorUsername: string;
    // imgBase64: string,
}

const PreviewDigitalProduct = (props: PreviewDigitalProductProps) => {
    const {
        type,
        title,
        price,
        description,
    } = props.product;

    const {openModal} = useModal();
    const {showModal} = useModalWithData<DigitalProduct>(ModalIds.PURCHASE_PROMPT);
    const checkLogin = useRequiredLogin({onLoginRequired: openModal.bind(this, ModalIds.LOGIN_REQUIRED)});
    const checkFollow = useRequiredFollow(props.creatorUsername, {
        onFollowRequired: openModal.bind(this, ModalIds.FOLLOW_REQUIRED)
    });

    const handleBuyDigitalProduct = async (event?: React.MouseEvent) => {
        if (!checkLogin()) return;
        const isAllowed = await checkFollow(event);
        if (!isAllowed) return;
        showModal(props.product);
    };

    return (
        <WrapperCard className={classNames(
            "w-full flex flex-col gap-3 !p-3"
        )}>
            <div className='flex justify-between'>
                <div className='flex flex-col gap-1'>
                    <span className='text-primary font-semibold text-xxs uppercase leading-3 tracking-[1px]'>
                        {DigitalProductCategoryService.getCategoryName(type)}
                    </span>
                    <header className='leading-none font-semibold text-base'>
                        <h3>{title}</h3>
                    </header>
                    <p className='text-gray font-medium text-[0.875rem]'>
                        {description || 'No description available'}
                    </p>
                </div>
                <div
                    className='w-[72px] h-[72px] border border-special-gray rounded-2xl bg-gray-light flex justify-center items-center'>
                    <PhotoIcon className='w-5 h-5 text-gray'/>
                </div>
            </div>
            <div className='flex justify-between items-center'>
                <div className='flex gap-1 font-bold text-lg leading-5'>
                    {price}<Coin/>
                </div>
                <div className='flex gap-3 '>
                    <AppButton
                        label='Details'
                        className='!border-0 underline text-gray text-xs font-semibold border-special-gray cursor-not-allowed'
                    />
                    <AppButton
                        label='Buy'
                        onClick={handleBuyDigitalProduct}
                        className='text-gray text-[0.875rem] !rounded-2xl font-semibold border-special-gray
                        hover:text-white hover:bg-primary transition'
                    />
                </div>
            </div>
        </WrapperCard>
    )
}

export default PreviewDigitalProduct
