import React from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";

interface TextFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    type?: string;
    className?: string;
    isLoading?: boolean,
    isRequired?: boolean,
}

const TextFormField: React.FC<TextFormFieldProps> = (props: TextFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}} = useFormContext();
    const {
        name,
        label,
        type = 'text',
        className = '',
        isLoading,
        isRequired = false,
        ...rest
    } = props

    return (
        <div className='mb-4'>
            <div className={`relative w-full h-fit`}>
                <label htmlFor={name} className="absolute block text-xs font-medium top-2 left-[14px]">
                    {label}
                    {isRequired && <span className='text-red-500'>*</span>}
                </label>
                {isLoading
                    ? <SkeletonFormField/>
                    : <input
                        type={type}
                        id={name}
                        {...register(name)}
                        placeholder="Write"
                        className={`border-2 border-gray-light rounded-xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary ${errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400'} ${className}`}
                        disabled={isSubmitting}
                        {...rest}
                    />}
            </div>
            {errors[name] && <p className="text-red-500 text-xs mt-1">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default TextFormField;
