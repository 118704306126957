import {useState} from 'react';
import WalletApiService from "../services/wallet-api.service";

export const useWalletApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getUserBalance = async (): Promise<number> => {
        setLoading(true);
        setError(null);
        try {
            return await WalletApiService.getBalance();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        getUserBalance,
    };
};
