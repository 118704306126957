import WrapperCard from "../../common/components/wrapper-card";
import {ContactType} from "../types/communications-type.interface";
import {ChatBubbleLeftIcon, PhoneIcon, VideoCameraIcon} from "@heroicons/react/24/outline";
import Tooltip from "../../common/components/tooltip";
import {useRef} from "react";
import {classNames} from "../../../utils/class-names";
import Coin from "../../core/components/coin";

interface CardBaseOfferProps {
    title: string,
    description: string,
    price: number,
    type: ContactType
    shortInfoTooltip?: string,
    fullInfoTooltip?: string,
    isEnabled: boolean,
}

const CardBaseOffer = (props: CardBaseOfferProps) => {
    const {price, description, title,type,shortInfoTooltip, isEnabled, fullInfoTooltip} = props;
    const contentRef = useRef(null);

    const renderIcon = (type: ContactType) => {
        switch (type) {
            case ContactType.VIDEO_CALL:
                return <VideoCameraIcon className="w-4 h-4" strokeWidth={3}/>;
            case ContactType.CHAT:
                return <ChatBubbleLeftIcon className="w-4 h-4 font-bold" strokeWidth={3} />;
            case ContactType.CALL:
                return <PhoneIcon className="w-4 h-4" strokeWidth={3}/>;
            default:
                return null;
        }
    };

    return (
        <WrapperCard className={classNames(
            'w-full !h-full !min-h-full !p-3 ',
            isEnabled ? 'bg-gray-light border-0' : 'bg-white text-gray'
        )}>
            <div ref={contentRef} className={classNames(
                'flex flex-col gap-3 h-full',
                shortInfoTooltip && 'cursor-pointer'
            )}>
                <div className='flex gap-2 items-center font-semibold text-[0.625rem] uppercase'>
                    {renderIcon(type)}
                    {title}
                </div>
                {isEnabled ? (
                    <div className='flex flex-col'>
                        <span className='text-[1.4rem] flex items-center gap-2 font-bold'>{price}<Coin/></span>
                        <div className='text-xs items-center gap-1 text-gray'>
                            <span>{description}</span>
                            {shortInfoTooltip && (
                                <Tooltip
                                    preferredPositionX='right'
                                    preferredPositionY='top'
                                    shortInfo={shortInfoTooltip}
                                    info={fullInfoTooltip}
                                    triggerMode='click'
                                    clickActivationRef={contentRef}/>
                            )}
                        </div>
                    </div>
                ): (
                    <div className='text-xs font-semibold text-gray'>
                        Unavailable from this Creator
                    </div>
                )}

            </div>
        </WrapperCard>
    )
}

export default CardBaseOffer
