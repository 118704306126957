import React, {createContext, ReactNode, useEffect, useState} from 'react';
import {PublicUserProfile} from "../../core/models/public-user-profile.interface";
import {UserProfile} from "../../core/types/user-profile.interface";
import useAuth from "../../auth/hooks/use-auth";
import {useUserProfileApi} from "../../core/hooks/use-user-profile-api";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import {DigitalProduct} from "../../core/types/digital-product.interface";
import {switchMap} from "../../../axios/switch-map";
import {forkJoin} from "../../../axios/fork-join";
import {useWalletApi} from "../../core/hooks/use-wallet-api";

export interface UserProfileContextProps<T> {
    userProfile: T | null;
    balance: number | null;
    digitalProducts: DigitalProduct[];
    isFollowed: boolean;
    isOnline: boolean;
    loading: boolean;
    error: string | null;
    followCreator: () => Promise<void>;
    unfollowCreator: () => Promise<void>;
    visitProfile: () => Promise<T>;
    fetchProfile: () => Promise<T>;
    fetchFollowStatus: () => Promise<void>;
    fetchOnlineStatus: () => Promise<void>;
}

interface UserProfileProviderProps {
    children: ReactNode;
    username?: string;
    initFetch?: boolean;
}

export const UserProfileContext = createContext<UserProfileContextProps<any> | undefined>(undefined);

export const UserProfileProvider = <T extends UserProfile | PublicUserProfile>(props: UserProfileProviderProps) => {
    const [userProfile, setUserProfile] = useState<T | null>(null);
    const [digitalProducts, setDigitalProducts] = useState<DigitalProduct[]>([]);
    const [isFollowed, setIsFollowed] = useState(false);
    const [balance, setBalance] = useState<number | null>(null);
    const [isOnline, setIsOnline] = useState(false);
    const [loading, setLoading] = useState(false);
    const {isAuthenticated} = useAuth();
    const {
        children,
        username,
        initFetch = false
    } = props;
    const {
        getUserProfile,
        getPublicUserProfile,
        followCreator,
        unfollowCreator,
        isCreatorFollowed,
        isContentCreatorOnline,
        visitProfile,
        error,
        setError
    } = useUserProfileApi();
    const {getOwnerDigitalProducts} = useDigitalProductApi();
    const {getUserBalance} = useWalletApi();

    const fetchProfile = async () => {
        setLoading(true);
        setError(null);
        try {
            if (username) {
                const profile = await getPublicUserProfile(username) as T;
                setUserProfile(profile);
                return profile
            } else {
                const profile = await getUserProfile() as T;
                setUserProfile(profile);
                return profile
            }
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchFollowStatus = async () => {
        setError(null);
        try {
            if (username) {
                const followed = await isCreatorFollowed(username);
                setIsFollowed(followed);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const fetchUserBalance = async () => {
        setError(null);
        try {
            if (isAuthenticated) {
                const balance: number = await getUserBalance();
                setBalance(balance);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const fetchOnlineStatus = async () => {
        setError(null);
        try {
            if (username) {
                const onlineStatus = await isContentCreatorOnline(username);
                setIsOnline(onlineStatus);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    const fetchDigitalProducts = async (userId: string) => {
        setError(null);
        try {
            if (userId) {
                const products = await getOwnerDigitalProducts(userId);
                setDigitalProducts(products);
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    useEffect(() => {
        if (initFetch && username) {
            const fetchData = async () => {
                setLoading(true);
                setError(null);
                try {
                    await switchMap(
                        () => fetchProfile(),
                        (profileData: any) => {
                            return forkJoin({
                                onlineStatus: fetchOnlineStatus(),
                                balance: fetchUserBalance(),
                                visitProfile: visitProfile(username),
                                digitalProducts: fetchDigitalProducts(profileData.id),
                                followStatus: isAuthenticated ? fetchFollowStatus() : Promise.resolve(null),
                            });
                        }
                    );
                } catch (err: any) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [username, initFetch, isAuthenticated]);


    return (
        <UserProfileContext.Provider
            value={{
                userProfile,
                digitalProducts,
                isFollowed,
                isOnline,
                loading,
                error,
                balance,
                followCreator: () => followCreator(username!),
                unfollowCreator: () => unfollowCreator(username!),
                visitProfile: () => visitProfile(username!),
                fetchProfile,
                fetchFollowStatus,
                fetchOnlineStatus,
            }}
        >
            {children}
        </UserProfileContext.Provider>
    );
};
