import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {NavigationProfile} from "../constants/navigation-profile";

const ProfileMenu = () => {
    const [active, setActive] = useState<string>(NavigationProfile[0].href);

    const handleTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabHref: string) => {
        event.preventDefault();
        setActive(tabHref)
        const element = document.getElementById(tabHref);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    return (
        <nav className="flex gap-2 px-4 py-3 overflow-x-auto">
            {NavigationProfile.map((item: any) => (
                    <Link
                        key={item.name}
                        to={`${item.href}`}
                        className={
                            active === item.href
                                ? 'border-b border-black text-black text-sm font-semibold px-3 py-2 text-nowrap'
                                : 'text-gray text-sm font-semibold px-3 py-2 text-nowrap'
                        }
                        onClick={(event) => handleTabClick(event, item.href)}
                    >
                        {item.name}
                    </Link>
                )
            )}
        </nav>
    );
};

export default ProfileMenu;
