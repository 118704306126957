import {ComponentType} from "../types/component.interface";
import {classNames} from "../../../utils/class-names";

interface WrapperCardProps {
    children: ComponentType,
    className?: string,
}

const WrapperCard = (props: WrapperCardProps) => {
    const {children, className} = props;

    return (
        <div className={classNames(
            'w-fit h-fit rounded-3xl border border-special-gray p-4',
            className
        )}>
            {children}
        </div>
    )
}

export default WrapperCard
