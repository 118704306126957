import React, {useContext, useState} from "react";
import ProfileBackground from "../components/profile-background";
import ProfileImage from "../components/profile-image";
import ShortUserData from "../components/short-user-data";
import FollowButton from "../components/follow-button";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import useAuth from "../../auth/hooks/use-auth";
import ProfileMenu from "../components/profile-menu";
import {UserAuthority} from "../../auth/types/user-authority.interface";
import PublicProfileSettingsButton from "../../../layout/components/public-profile-settings-button";
import {NavigationProfileAnchors} from "../types/navigation-profile-anchors.interface";
import CardBaseOffer from "../components/card-base-offer";
import {ContactType} from "../types/communications-type.interface";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/public-user-profile.interface";
import PreviewDigitalProduct from "../../digital-products/components/preview-digital-product";
import AppButton from "../../common/components/app-button";
import PurchaseProductModal from "../../purchases/components/purchase-product-modal";
import Coin from "../../core/components/coin";
import {PlusIcon} from "@heroicons/react/24/solid";
import {useNavigate} from "react-router-dom";

const PublicProfile = () => {
    const {currentUser} = useAuth();
    // TODO probably this variable in the future will be get from user settings about display products
    const initialMaxDisplayProducts = 3;
    const [maxDisplayProducts, setMaxDisplayProducts] = useState<number>(initialMaxDisplayProducts);
    const {
        userProfile,
        isFollowed,
        loading,
        followCreator,
        unfollowCreator,
        balance,
        digitalProducts
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const navigate = useNavigate();

    const isDisabledFollow = () => {
        return currentUser?.username?.toLowerCase() === userProfile?.username?.toLowerCase()
    }

    const showMoreProducts = () => {
        setMaxDisplayProducts(prev => prev + initialMaxDisplayProducts);
    };

    return (
        <AppContentWrapper className='bg-white rounded-t-[1.5rem] md:p-3 md:max-w-[1400px]'>
            <div className='relative inset-0 h-[260px]'>
                <ProfileBackground backgroundImage={userProfile?.backgroundProfile!}/>
            </div>
            <div className='p-4 md:px-0 w-full h-full bg-white rounded-t-[2rem] relative top-[-30px] left-0 md:flex
            md:max-w-[800px] gap-4 md:mx-auto md:top-0'>
                <div className='flex flex-col gap-3 sticky top-0 left-0 min-w-[250px]'>
                    <div
                        className='absolute top-[-60px] md:top-[-75px] left-1/2 md:left-0 translate-x-[-50%] md:translate-x-0 w-fit'>
                        <ProfileImage avatar={userProfile?.avatar!} role={UserAuthority.CONTENT_CREATOR}/>
                    </div>
                    <div className='mt-[80px] md:mt-[65px]'></div>
                    <ShortUserData
                        fullName={userProfile?.username}
                        shortDescription={''}
                        username={userProfile?.username + ''}
                    />
                    <div className='flex gap-3 h-fit mt-4 items-center'>
                        <div className='flex-grow h-fit'>
                            <FollowButton
                                isFollowed={isFollowed}
                                onFollow={followCreator}
                                onUnfollow={unfollowCreator}
                                disabled={isDisabledFollow() || loading}/>
                        </div>
                        <PublicProfileSettingsButton/>
                    </div>
                </div>
                <div className='w-full overflow-hidden flex flex-col gap-5'>
                    <ProfileMenu/>
                    <section id={NavigationProfileAnchors.CONTACT_PRICING} className='flex gap-3'>
                        <div className='w-full'>
                            <CardBaseOffer
                                isEnabled={true}
                                type={ContactType.CHAT}
                                shortInfoTooltip='Its more data.'
                                title='Chat'
                                description='for message block'
                                price={userProfile?.chatPrice!}/>
                        </div>
                        <div className='w-full'>
                            <CardBaseOffer
                                isEnabled={false}
                                type={ContactType.CALL}
                                title='Voice'
                                description='for 1 min'
                                price={userProfile?.callPrice!}/>
                        </div>
                        <div className='w-full'>
                            <CardBaseOffer
                                isEnabled={false}
                                type={ContactType.VIDEO_CALL}
                                title='Video'
                                description='for 1 min'
                                price={userProfile?.videoCallPrice!}/>
                        </div>
                    </section>
                    <section>
                        {balance !== null && (
                            <div>
                                <div className='flex gap-3 items-center'>
                                    <h2 className='font-bold text-lg leading-5'>Your balance</h2>
                                    <span
                                        onClick={()=> navigate('/wallet')}
                                        className='cursor-pointer rounded-full bg-primary-pastel text-primary-darken p-2 items-center justify-center'>
                                        <PlusIcon className='w-5 h-5'/>
                                    </span>
                                </div>
                                <span className='font-bold  flex gap-1 items-center text-xl'>{balance} <Coin/></span>
                            </div>
                        )}
                    </section>
                    <section id={NavigationProfileAnchors.DIGITAL_PRODUCTS} className='flex flex-col gap-3'>
                        <header className='flex gap-3 items-center'>
                            <h2 className='font-bold text-lg leading-5'>Digital products</h2>
                            <span className='w-[24px] h-[24px] p-1 bg-gray-light text-center text-gray font-semibold
                            text-xs rounded-full'>
                                {digitalProducts.length}
                            </span>
                        </header>
                        <div className='flex flex-col gap-3'>
                            {digitalProducts.slice(0, maxDisplayProducts).map((product, i) => (
                                <PreviewDigitalProduct product={product} key={i}
                                                       creatorUsername={userProfile?.username + ''}/>
                            ))}
                        </div>
                        {/*TODO this button should display sidebar with all products*/}
                        {/*{digitalProducts.length > maxDisplayProducts && (*/}
                        {/*    <AppButton*/}
                        {/*        onClick={showMoreProducts}*/}
                        {/*        className='text-blue-500 hover:text-blue-700 font-semibold mt-3'*/}
                        {/*        label='Show more'/>*/}
                        {/*)}*/}
                        {digitalProducts.length > maxDisplayProducts && (
                            <AppButton
                                onClick={showMoreProducts}
                                className='text-gray text-xs hover:text-primary font-semibold border-special-gray'
                                label={`Show all products (+${digitalProducts.length - maxDisplayProducts})`}/>
                        )}
                    </section>
                    <section id={NavigationProfileAnchors.BIO}>
                        <header className='font-bold text-lg'><h3>Bio</h3></header>
                        <p className='font-medium text-base text-gray'>
                            {userProfile?.bio}
                        </p>
                    </section>
                </div>
            </div>
            <PurchaseProductModal/>
        </AppContentWrapper>
    )
}

export default PublicProfile;
