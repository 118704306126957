import React from 'react';
import SendTokenResetPasswordForm from "../../components/send-token-reset-password-form";

interface SendTokenResetPasswordViewProps {
    onSubmit: (token: string, newPassword: string) => Promise<void>
}

const SendTokenResetPasswordView: React.FC<SendTokenResetPasswordViewProps> = (props: SendTokenResetPasswordViewProps) => {
    return (
        <div className="flex flex-col h-full bg-gray-100 w-full">
            <div className="flex flex-col flex-grow gap-y-3 bg-white rounded w-full justify-center">
                <p className="mb-4 text-sm text-gray">
                    Write the code you received on your email.
                </p>
                <SendTokenResetPasswordForm onSubmit={props.onSubmit}/>
            </div>
        </div>
    );
};

export default SendTokenResetPasswordView;
