import React from 'react';
import {Base64Prefix} from "../../common/constats/base64-prefix";

interface ProfileBackgroundProps {
    backgroundImage: string | ArrayBuffer;
}

const ProfileBackground: React.FC<ProfileBackgroundProps> = ({ backgroundImage }) => {
    return (
        <div
            className="w-full h-full bg-cover bg-center md:rounded-3xl"
            style={{ backgroundImage: `url(${Base64Prefix}${backgroundImage})` }}
        />
    );
};

export default ProfileBackground;
