import React, {useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextFormField from '../../common/components/forms/text-form-field';
import PasswordFormField from "../../common/components/forms/password-form-field";
import AppButton from "../../common/components/app-button";
import useAuth from "../hooks/use-auth";
import {RegisterBody} from "../types/register-body.interface";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import SelectFormField from "../../common/components/forms/select-form-field";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import DictionaryService from "../../core/services/dictionary.service";
import NumberFormField from "../../common/components/forms/number-form-field";

const schema = yup.object({
    firstname: yup.string().required('Firstname is required'),
    lastname: yup.string().required('Lastname is required'),
    genderId: yup.number().required('Gender is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup.string().required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(255, 'Password must be less than 255 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
    contactNumber: yup.string().required('Phone number is required'),
    isAdult: yup.boolean().required('You must be over 18'),
    emailNotifications: yup.boolean().required('You must be over 18')
}).required();

const RegisterForm = () => {
    const {register} = useAuth();
    const formRef = useRef<HTMLFormElement>(null);
    const methods = useForm<RegisterBody>({
        resolver: yupResolver(schema),
        mode: 'onTouched',
    });
    const navigate = useNavigate();

    const handleRegister = async () => {
        const body = methods.getValues();
        await register(body)
            .then(() => navigate('/auth/login'))
            .catch(() => {
            })
            .finally()
    };

    return (
        <FormProvider {...methods}>
            <form ref={formRef} onSubmit={methods.handleSubmit(handleRegister)}
                  className="flex-grow flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full sm:py-20 max-h-[800px] my-auto">

                <div className="flex items-center gap-4 text-primary-darken">
                    <button type='button' className='p-3 bg-gray-light rounded-lg' onClick={() => navigate("/auth")}>
                        <ChevronLeftIcon className='w-4 h-4'/>
                    </button>
                    <h2 className="text-3xl font-bold">Create account</h2>
                </div>

                <div className='flex-grow overflow-y-auto overflow-x-hidden p-1 py-5'>
                    <TextFormField name="firstname" label="Firstname*" placeholder="Enter your firstname"/>
                    <TextFormField name="lastname" label="Lastname*" placeholder="Enter your lastname"/>
                    <SelectFormField
                        name='genderId'
                        parentWrapperRef={formRef}
                        labelKey='name' valueKey='id'
                        label='Select your gender*'
                        fetchOptions={() => DictionaryService.getGenders()}
                    />

                    <TextFormField name="email" label="Email*" placeholder="Enter your email"/>

                    <PasswordFormField name="password" label="Password*" placeholder="Create your password"
                                       showPasswordToggle={true}/>

                    <NumberFormField maxLength={9} type='number' name="contactNumber" label="Phone number*"
                                     placeholder="Enter your phone number"/>

                    <div className="flex flex-wrap gap-3 items-center">
                        <SwitcherFormField name='isAdult' label="I'm over 18*"/>
                        <SwitcherFormField name='emailNotifications' label="I want to receive email notifications"/>
                    </div>
                </div>

                <AppButton
                    type='submit'
                    onClick={handleRegister}
                    label={'Create'}
                    className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
            </form>
        </FormProvider>
    );
};

export default RegisterForm;
