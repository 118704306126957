import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextFormField from '../../common/components/forms/text-form-field';
import useAuth from "../hooks/use-auth";
import AppButton from "../../common/components/app-button";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import PasswordFormField from "../../common/components/forms/password-form-field";
import {useRedirect} from "../hooks/use-redirect";

const schema = yup.object({
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup.string().required('Password is required'),
}).required();

const LoginForm = () => {
    const {login, loading} = useAuth();
    const navigate = useNavigate();
    const { intendedRoute, setIntendedRoute } = useRedirect();
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched', // Validate on blur
        disabled: loading,
    });

    const handleLogin = async () => {
        const body = methods.getValues();
        await login(body.email, body.password)
            .then(()=> {
                const redirectTo = intendedRoute || '/';
                setIntendedRoute(null);
                navigate(redirectTo);
            })
            .catch(() => {
            })
            .finally();
    };

    return (
        <FormProvider {...methods}>
            <form
                className="flex-grow flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full sm:py-20 max-h-[800px] my-auto">

                <div className='flex-grow'>
                    <div className="flex items-center mb-8 gap-4 text-primary-darken">
                        <button type='button' className='p-3 bg-gray-light rounded-lg'
                                onClick={() => navigate("/auth")}>
                            <ChevronLeftIcon className='w-4 h-4'/>
                        </button>
                        <h2 className="text-3xl font-bold">Sign in</h2>
                    </div>

                    <div className="flex flex-col gap-4">
                        <button
                            type='button'
                            className="border-2 border-gray-light rounded-xl py-3 px-4 flex items-center justify-between">
                            <span>With Google</span>
                            <span className="rounded-full border border-gray-300 w-5 h-5"></span>
                        </button>
                        <button
                            type='button'
                            className="border-2 border-gray-light rounded-xl py-3 px-4 flex items-center justify-between">
                            <span>With Facebook</span>
                            <span className="rounded-full border border-gray-300 w-5 h-5"></span>
                        </button>
                        <button
                            type='button'
                            className="border-2 border-gray-light rounded-xl py-3 px-4 flex items-center justify-between">
                            <span>With Instagram</span>
                            <span className="rounded-full border border-gray-300 w-5 h-5"></span>
                        </button>
                    </div>

                    <div className="flex items-center justify-center my-6">
                        <span className="border-t w-full border-primary-darken"></span>
                        <span className="px-4 text-xs text-primary-darken font-semibold">OR</span>
                        <span className="border-t w-full border-primary-darken"></span>
                    </div>

                    <TextFormField
                        name="email"
                        label="E-mail"
                        placeholder="Write e-mail..."
                    />

                    <div>
                        <PasswordFormField
                            name="password"
                            label="Password"
                            placeholder="Write password..."
                            showPasswordToggle={true}
                        />

                        <div className="flex justify-end text-sm text-gray-500">
                            <Link to="/auth/forgot-password">Forgot password?</Link>
                        </div>
                    </div>
                </div>

                <AppButton
                    type='button'
                    label={'Sign In'}
                    onClick={handleLogin}
                    className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
            </form>
        </FormProvider>
    );
};

export default LoginForm;
