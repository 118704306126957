export const forkJoin = async <T extends { [key: string]: Promise<any> }>(
    requests: T
): Promise<{ [K in keyof T]: any }> => {
    try {
        const entries = Object.entries(requests) as [keyof T, Promise<any>][];
        const results = await Promise.all(entries.map(([_, request]) => request));

        return entries.reduce((acc, [key], index) => {
            acc[key] = results[index].data; // Zakładamy, że interesuje nas `response.data`
            return acc;
        }, {} as { [K in keyof T]: any });
    } catch (error) {
        throw error;
    }
};
