import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import AppWrapper from "./components/app-wrapper";
import AppSidebarWrapper from "./components/app-sidebar-wrapper";
import {SidebarProvider} from "./contexts/sidebar-context";
import {SidebarIds} from "./types/sidebar-ids.interface";
import AppMenu from "./app-menu";
import WalletPage from "../pages/wallet-page";
import HomePage from "../pages/home-page";
import AppTopBarAnnouncement from "./components/app-top-bar-announcement";
import ProfilePage from "../pages/profile-page";


const MainLayer = () => {
    return (
        <SidebarProvider>
            <div className={'w-full bg-white h-svh flex flex-col'}>
                <AppTopBarAnnouncement/>
                <div className='w-full flex h-full'>
                    <AppSidebarWrapper id={SidebarIds.MENU} side='left' classNameSidebar='lg:max-w-sm'>
                        <AppMenu/>
                    </AppSidebarWrapper>
                    <AppWrapper className='w-full flex-grow overflow-hidden lg:p-4'>
                        <Routes>
                            <Route index element={<HomePage/>}/>
                            <Route path='/wallet' element={<WalletPage/>}/>
                            <Route path='/my-profile' element={<ProfilePage/>}/>
                            <Route path="*" element={<Navigate to="/not-found"/>}/>
                        </Routes>
                    </AppWrapper>
                </div>
            </div>
        </SidebarProvider>
    )
}
export default MainLayer
