import ApiService from "../../../axios/api.service";
import NotificationService from "../../common/services/notification.service";
import {UserProfile} from "../types/user-profile.interface";
import {PublicUserProfile} from "../models/public-user-profile.interface";
import {Pricing} from "../types/pricing.interface";

class ProfileApiService extends ApiService {
    // Pobieranie profilu użytkownika
    public async getUserProfile(): Promise<UserProfile> {
        return await this.get<UserProfile>(`/profile`)
            .catch((error) => {
                NotificationService.error("Error loading user profile");
                throw error;
            });
    }

    // Pobieranie publicznego profilu
    public async getPublicUserProfile(username: string): Promise<PublicUserProfile> {
        return await this.get<PublicUserProfile>(`/profile/${username}`)
            .catch((error) => {
                NotificationService.error("Error loading public profile");
                throw error;
            });
    }

    // Aktualizacja profilu użytkownika
    public async updateProfile(data: any): Promise<void> {
        return await this.put<void>(`/profile/update`, data)
            .then(() => NotificationService.success("Profile updated successfully"))
            .catch((error) => {
                NotificationService.error("Error updating profile");
                throw error;
            });
    }

    // Pobieranie cen usług użytkownika
    public async getPricing(): Promise<Pricing> {
        return await this.get<Pricing>(`/profile/pricing`)
            .catch((error) => {
                NotificationService.error("Error loading pricing");
                throw error;
            });
    }

    // Zmiana ceny za czat
    public async changeChatPrice(price: number): Promise<void> {
        return await this.put<void>(`/profile/chat-price/${price}`, {})
            .catch((error) => {
                NotificationService.error("Error updating chat price");
                throw error;
            });
    }

    // Śledzenie użytkownika
    public async followCreator(username: string): Promise<void> {
        return await this.post<void>(`/profile/follow/${username}`, {})
            .then(() => NotificationService.success(`Started following ${username}`))
            .catch((error) => {
                NotificationService.error("Error following creator");
                throw error;
            });
    }

    // Odśledzenie użytkownika
    public async unfollowCreator(username: string): Promise<void> {
        return await this.post<void>(`/profile/unfollow/${username}`, {})
            .then(() => NotificationService.success(`Stopped following ${username}`))
            .catch((error) => {
                NotificationService.error("Error unfollowing creator");
                throw error;
            });
    }

    // Sprawdzenie, czy użytkownik jest śledzony
    public async isCreatorFollowed(username: string): Promise<boolean> {
        return await this.get<boolean>(`/profile/followed/${username}`)
            .catch((error) => {
                NotificationService.error("Error checking follow status");
                throw error;
            });
    }

    public async isContentCreatorOnline(username: string): Promise<boolean> {
        return await this.get<boolean>(`/profile/status/${username}`)
            .catch((error) => {
                NotificationService.error("Error checking online status");
                throw error;
            });
    }

    public async visitProfile(username: string): Promise<void> {
        return await this.put<void>(`/profile/visit-counter/${username}`, {})
            .catch((error) => {
                NotificationService.error("Error visiting profile");
                throw error;
            });
    }
}

export default new ProfileApiService()
