import ForgotPasswordView from "../features/auth/views/forgot-password/forgot-password";
import React, {useState} from "react";
import SendTokenResetPasswordView from "../features/auth/views/send-token-reset-password/send-token-reset-password";
import useAuth from "../features/auth/hooks/use-auth";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";

const ForgotPasswordPage = () => {
    const [step, setStep] = useState(1);
    const {getTokenToResetPassword, resetPassword} = useAuth();
    const navigate = useNavigate();

    const handleEmailSubmit = async (emailOrUsername: string) => {
        await getTokenToResetPassword(emailOrUsername)
            .then(() => setStep(2))
            .catch(() => {
            })
            .finally()
    };

    const handleVerificationCodeSubmit = async (code: string) => {
        await getTokenToResetPassword(code)
            .then(() => setStep(3))
            .catch(() => {
            })
            .finally()
    };

    const handleNewPasswordSubmit = async (token: string, newPassword: string) => {
        await resetPassword(token, newPassword)
            .then(() => navigate('/auth/login'))
            .catch(() => {
            })
            .finally()
    };

    const goToPrevStep = () => {
        if (step === 1) navigate('/auth/login');
        else setStep(prev => prev - 1);
    }

    return (
        <div className='flex flex-col h-dvh w-full items-center justify-center mx-auto'>
            <div className='flex flex-col h-full p-4 sm:py-20 max-h-[800px] max-w-md w-full'>
                <div className="flex items-center w-full mb-8 gap-4 text-primary-darken">
                    <button type='button' className='p-3 bg-gray-light rounded-lg' onClick={goToPrevStep}>
                        <ChevronLeftIcon className='w-4 h-4'/>
                    </button>
                    <h2 className="text-3xl font-bold">{step < 2 ? 'Forgot password' : 'Set new password'}</h2>
                </div>
                {step === 1 && <ForgotPasswordView onSubmit={handleEmailSubmit}/>}
                {step === 2 && <SendTokenResetPasswordView onSubmit={handleNewPasswordSubmit}/>}
                {/*{step === 3 && <ResetPasswordView onSubmit={handleNewPasswordSubmit}/>}*/}
            </div>
        </div>
    )
}

export default ForgotPasswordPage
