import React, {useContext} from "react";
import {PublicUserProfile} from "../../core/models/public-user-profile.interface";
import TextStatusUser from "./text-status-user";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";

interface ShortUserDataProps {
    fullName?: string,
    username: string,
    shortDescription?: string,
}

const ShortUserData = (props: ShortUserDataProps) => {
    const {
        shortDescription,
        username,
        fullName
    } = props
    const {isOnline} = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;

    return (
        <div className='flex flex-col items-center md:items-start'>
            <div className='font-extrabold text-[1.75rem] text-center'>
                {fullName}
            </div>
            <div className='text-gray font-medium text-xs flex gap-2 items-center'>
                <span>@{username}</span>
                <span className='block w-1 h-1 bg-gray rounded-full'></span>
                <TextStatusUser isOnline={isOnline}/>
            </div>
            {shortDescription && (
                <>
                    <div className='w-10 h-[1px] bg-gray my-3'></div>
                    <div className='text-sm text-gray font-medium text-center'>
                        {shortDescription}
                    </div>
                </>
            )}
        </div>
    )
}

export default ShortUserData
