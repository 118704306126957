import RegisterForm from "../features/auth/components/register-form";

const RegisterPage = () => {
    return (
        <div className=' flex h-dvh w-full items-center justify-center'>
            <RegisterForm/>
        </div>
    )
}

export default RegisterPage
