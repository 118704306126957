import {NavigationProfileAnchors} from "../types/navigation-profile-anchors.interface";

export const NavigationProfile = [
    {
        name: 'Contact pricing',
        href: NavigationProfileAnchors.CONTACT_PRICING,
        strictActive: false,
    },
    // {
    //     name: 'Subscription',
    //     href: '#subscription',
    //     strictActive: true,
    // },
    {
        name: 'Digital products',
        href: NavigationProfileAnchors.DIGITAL_PRODUCTS,
        strictActive: true,
    },
    {
        name: 'Bio',
        href: NavigationProfileAnchors.BIO,
        strictActive: true,
    },
    // {
    //     name: 'Social media',
    //     href: '#social-media',
    //     strictActive: true,
    // },
]
