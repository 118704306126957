import React, {useEffect} from 'react';
import {XMarkIcon} from "@heroicons/react/20/solid";
import {useSidebar} from "../hooks/use-sidebar";
import {useMediaQuery} from "react-responsive";
import {SidebarIds} from "../types/sidebar-ids.interface";
import {classNames} from "../../utils/class-names";
import {animated, useTransition} from "@react-spring/web";

interface SidebarWrapperProps {
    children: React.ReactNode;
    id: SidebarIds;
    forceMobile?: boolean;
    classNameSidebar?: string;
    side: 'left' | 'right';
}

const AppSidebarWrapper: React.FC<SidebarWrapperProps> = (props: SidebarWrapperProps) => {
    const {
        children,
        id,
        forceMobile = false,
        side,
        classNameSidebar,
    } = props
    const {isOpen, closeSidebar, registerSidebar} = useSidebar();
    const isDesktop = useMediaQuery({minWidth: 1024});
    const isMobileView = !isDesktop || forceMobile;

    const transitionsSidebar = useTransition(isOpen(id) || (isDesktop && !forceMobile), {
        from: {opacity: isMobileView ? 0 : 1},
        enter: {opacity: 1},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    const transitionsBackdrop = useTransition(isOpen(id) && isMobileView, {
        from: {opacity: 0},
        enter: {opacity: isMobileView ? 0.2 : 0},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    useEffect(() => {
        registerSidebar(id);
    }, [id]);

    return (
        <div className={classNames(
            'flex ',
            isMobileView && 'absolute inset-0',
            side === 'right' && 'flex-row-reverse'
        )}>
            {transitionsBackdrop((style, item) =>
                    item && (
                        <animated.div
                            style={style}
                            className={classNames(
                                isMobileView && "fixed inset-0 z-20 bg-gray-light"
                            )}
                        />
                    )
            )}
            {transitionsSidebar((style, item) =>
                    item && (
                        <animated.div
                            style={style}
                            className={classNames(
                                `bg-white z-30 ${classNameSidebar}`,
                                isDesktop && !forceMobile && 'flex h-full',
                                isMobileView && 'relative top-0 left-0 m-3 rounded-3xl max-w-sm shadow-lg max-h-full flex-grow'
                            )}
                        >
                            <div className="lg:static lg:block top-0 left-0 z-40 w-full h-full transition-all duration-300">
                                {children}
                            </div>
                        </animated.div>
                    )
            )}
            {(isMobileView && isOpen(id)) && (
                <button
                    className="z-30 w-fit h-fit mt-9 p-2 mr-3 rounded-xl bg-white focus:outline-none"
                    onClick={() => closeSidebar()}
                >
                    <XMarkIcon className="h-5 w-5 text-gray-600"/>
                </button>
            )}
        </div>
    );
};

export default AppSidebarWrapper;
