import React from 'react';
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import {useModal} from "../../common/hooks/use-modal";
import {ModalIds} from "../../common/types/modal-ids.interface";

interface FollowButtonProps {
    isFollowed: boolean;
    onFollow: () => Promise<void>;
    onUnfollow: () => Promise<void>;
    disabled: boolean;
}

const FollowButton: React.FC<FollowButtonProps> = ({isFollowed, onFollow, onUnfollow, disabled}) => {
    const {openModal} = useModal();
    const checkLogin = useRequiredLogin({onLoginRequired: () => openModal(ModalIds.LOGIN_REQUIRED)});

    //TODO REDRIECTION to chat with followed CC
    const handleClick = async (event?: React.MouseEvent) => {
        if (!checkLogin(event)) return;

        if (isFollowed) {
            await onUnfollow();
        } else {
            await onFollow();
        }
    };

    return (
        <div className="text-center">
            <AppButton
                className={classNames(
                    "w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary",
                    {
                        "bg-primary-pastel border-primary": isFollowed,
                    }
                )}
                onClick={() => handleClick()}
                label={isFollowed ? 'Unfollow' : 'Follow'}
                disabled={disabled}
            />
        </div>
    );
};

export default FollowButton;
