import React from 'react';
import AppWrapper from "../layout/components/app-wrapper";
import AppHeader from "../layout/app-header";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import withAnyProvider from "../features/common/hoc/with-any-provider";
import {ModalProvider} from "../features/common/contexts/modal-context";

const HomePage = () => {

    return (
        <AppWrapper>
            <AppHeader>
                <div className='flex gap-3'>
                    <div className='w-1/4 h-20 bg-orange-500 rounded-3xl'></div>
                    <div className='w-1/4 h-20 bg-orange-500 rounded-3xl'></div>
                    <div className='w-1/4  h-20 bg-orange-500 rounded-3xl'></div>
                    <div className='w-1/4  h-20 bg-orange-500 rounded-3xl'></div>
                </div>
                <div className='mt-3 flex gap-3'>
                    <div className='w-1/4 h-20 bg-orange-500 rounded-3xl'></div>
                    <div className='w-1/4 h-20 bg-orange-500 rounded-3xl'></div>
                    <div className='w-1/4  h-20 bg-orange-500 rounded-3xl'></div>
                    <div className='w-1/4  h-20 bg-orange-500 rounded-3xl'></div>
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem]'>
                Dashboard
                {/*<AppButton label={'otworz modal'} onClick={openModal.bind(this, ModalIds.TEST)}/>*/}
            </AppContentWrapper>
            {/*<AppModal id={ModalIds.TEST}>*/}
            {/*    <div>XDDD</div>*/}
            {/*</AppModal>*/}
        </AppWrapper>
    );
};

export default withAnyProvider(ModalProvider, HomePage);
