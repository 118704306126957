import {ReactNode} from "react";


type AppWrapperProps = {
    children: ReactNode;
    className?: string;
};

const AppWrapper = (props: AppWrapperProps) => {
    const {children, className} = props
    return (
        <div className={'mx-auto relative overflow-y-auto overflow-x-hidden '+className}>
            {children}
        </div>
    )
}

export default AppWrapper
