import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import {classNames} from "../../../../utils/class-names";

interface SwitcherFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    className?: string;
    showError?: boolean;
    onToggle?: (checked: boolean) => void;
}

const SwitcherFormField: React.FC<SwitcherFormFieldProps> = (props: SwitcherFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}, getValues} = useFormContext();
    const {
        name,
        className = '',
        disabled,
        showError,
        onToggle,
        ...rest
    } = props;

    const isDisabled = disabled !== undefined ? disabled : isSubmitting;
    const [checked, setChecked] = useState(rest.checked || false);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = e.target.checked;
        setChecked(newChecked);
        if (onToggle) {
            onToggle(newChecked);
        }
    };

    useEffect(() => {
        setChecked(getValues(name));
    }, []);

    return (
        <div className="cursor-pointer">
            <label htmlFor={name} className="flex items-center gap-2 cursor-pointer">
                <div className="relative">
                    <input
                        type="checkbox"
                        id={name}
                        className="sr-only"
                        disabled={isDisabled}
                        checked={checked}
                        {...register(name, {
                            onChange: handleOnChange,
                        })}
                        {...rest}
                    />
                    <div
                        className={classNames(
                            "block w-10 h-6 bg-gray rounded-full",
                            isDisabled ? "bg-gray-300" : "bg-gray-200",
                            checked ? 'bg-primary' : '',
                        )}
                    />
                    <div
                        className={classNames(
                            "absolute left-1 top-1 w-4 h-4 rounded-full transition-transform transform",
                            "bg-white flex items-center justify-center",
                            errors[name] ? "border-red-500" : "border-gray-300",
                            isDisabled ? "bg-gray-300" : "bg-white",
                            "checked:left-5 checked:bg-primary"
                        )}
                        style={{transform: `translateX(${checked ? '100%' : '0'})`}}
                    >
                        {checked ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3 text-primary"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3 text-gray-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        )}
                    </div>
                </div>
                {props.label && <span className="text-gray-700 theme-dark:text-gray-50">{props.label}</span>}
            </label>
            {showError && errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default SwitcherFormField;
