import React, {useEffect} from "react";
import AppWrapper from "../layout/components/app-wrapper";
import {useNavigate, useParams} from "react-router-dom";
import {UserProfileProvider} from "../features/profile/contexts/user-profile-context";
import PublicProfile from "../features/profile/views/public-profile";
import {PublicUserProfile} from "../features/core/models/public-user-profile.interface";
import useAuth from "../features/auth/hooks/use-auth";

const AppPublicProfilePage = () => {
    const params = useParams();
    const {currentUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser?.username === params['username']) {
            navigate('/my-profile')
        }
    });

    return (
        <AppWrapper>
            <UserProfileProvider<PublicUserProfile>
                username={params['username']}
                initFetch={true}>
                <PublicProfile/>
            </UserProfileProvider>
        </AppWrapper>
    )
}

export default AppPublicProfilePage
