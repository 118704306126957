import React from 'react';
import { useNavigate } from "react-router-dom";
import AppButton from "../features/common/components/app-button";

const AppLandingPage: React.FC = () => {
    const navigate = useNavigate();

    const handleLogin = (): void => {
        navigate("/auth/login");
    };

    const handleRegister = (): void => {
        navigate("/auth/register");
    };

    return (
        <div className="flex justify-center items-center h-dvh w-screen bg-gray-50">
            <div className="flex flex-col h-full max-h-full mx-auto overflow-hidden">
                <div className="w-screen h-[320px] bg-gray-100 mb-8 flex items-center justify-center">
                    <img
                        src={require('../assets/images/app-landing-background.png')}
                        alt="App illustration"
                        className="rounded-b-3xl object-cover w-full h-full object-center"
                    />
                </div>

                <div className='p-4 flex flex-col justify-between max-w-md mx-auto h-full max-h-full'>
                    <div className="flex flex-col mb-8 ">
                        <h1 className="text-3xl font-bold mb-2 text-black">
                            Welcome in Yurs
                        </h1>
                        <p className="text-gray font-medium text-sm">
                            Short description about app. Ex, check out this cool app! It’s super easy to use and will make your life a whole lot easier.
                        </p>
                    </div>

                    <div className="flex flex-col gap-y-4 w-full items-center">
                        <AppButton
                            type='button'
                            label='Sign in'
                            onClick={handleLogin}
                            className='w-full text-primary border border-special-gray text-sm py-[9px] h-fit rounded-lg font-semibold bg-transparent'/>
                        <AppButton
                            type='button'
                            label='Create an account'
                            onClick={handleRegister}
                            className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppLandingPage;
