import ApiService from "../../../axios/api.service";
import {DictionaryEntry} from "../types/dictionary-entry.interface";

class DictionaryService extends ApiService {

    public async getGenders(): Promise<DictionaryEntry[]> {
        return await this.get<DictionaryEntry[]>(`/users/dictionary/gender`, {});
    }
}

export default new DictionaryService()


