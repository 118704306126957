import ApiService from "../../../axios/api.service";

class WalletApiService extends ApiService {

    public async getBalance(): Promise<number> {
        return await this.get<number>(`/wallet/balance`, {})
            .catch((error) => {
                throw error;
            });
    }
}

export default new WalletApiService()
