import {Cog6ToothIcon, HeartIcon, HomeIcon, UserCircleIcon} from "@heroicons/react/24/outline";
import {UserAuthority} from "../../features/auth/types/user-authority.interface";

export const navigation = [
    {
        name: 'Home',
        href: '/',
        Icon: HomeIcon,
        strictActive: false,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Your wallet',
        href: '/wallet',
        Icon: HeartIcon,
        strictActive: true,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Become creator',
        href: '/become-creator',
        Icon: HeartIcon,
        strictActive: true,
        accessRoles: [UserAuthority.FAN]
    },
    {
        name: 'My profile',
        href: '/my-profile',
        Icon: UserCircleIcon,
        strictActive: true,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
    {
        name: 'Settings',
        href: '/settings',
        Icon: Cog6ToothIcon,
        strictActive: true,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    },
]
