import React, {useState} from 'react';
import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../types/modal-ids.interface";
import {useModal} from "../hooks/use-modal";
import AppButton from "../../common/components/app-button";
import {useUserProfileApi} from "../../core/hooks/use-user-profile-api";

const FollowRequiredModal: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const {closeModal} = useModal();
    const {followCreator} = useUserProfileApi();

    const handleCloseModal = () => closeModal(ModalIds.FOLLOW_REQUIRED);

    const handleFollowingCC = async () => {
        setLoading(true)
        try {
            await followCreator('Ankaaaaaa');
            handleCloseModal();
        } catch (err: any) {
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return (
        <AppModal key={ModalIds.FOLLOW_REQUIRED} id={ModalIds.FOLLOW_REQUIRED} disableScroll={true}>
            <div>
                <h2 className="text-xl font-semibold mb-4">Follow Required</h2>
                <p className="text-gray mb-6">You need to following Content Creator for perform this action.</p>
                <div className="flex justify-end gap-4">
                    <AppButton
                        onClick={handleCloseModal}
                        className="text-primary-darken border-0 px-4 py-2 rounded-lg border-primary-darken hover:border transition"
                        label='Cancel'
                    />
                    <AppButton
                        onClick={handleFollowingCC}
                        disabled={loading}
                        className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-darken transition"
                        label='Follow'
                    />
                </div>
            </div>
        </AppModal>
    );
};

export default FollowRequiredModal;
