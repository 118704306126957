import { useState } from 'react';
import ProfileApiService from "../services/profile-api.service";
import { UserProfile } from "../types/user-profile.interface";
import { PublicUserProfile } from "../models/public-user-profile.interface";

export const useUserProfileApi = () => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const getUserProfile = async (): Promise<UserProfile> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.getUserProfile();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getPublicUserProfile = async (username: string): Promise<PublicUserProfile> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.getPublicUserProfile(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const followCreator = async (username: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await ProfileApiService.followCreator(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const unfollowCreator = async (username: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await ProfileApiService.unfollowCreator(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const isCreatorFollowed = async (username: string): Promise<boolean> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.isCreatorFollowed(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const isContentCreatorOnline = async (username: string): Promise<boolean> => {
        setLoading(true);
        setError(null);
        try {
            return await ProfileApiService.isContentCreatorOnline(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const visitProfile = async (username: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await ProfileApiService.visitProfile(username);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        getUserProfile,
        getPublicUserProfile,
        followCreator,
        unfollowCreator,
        isCreatorFollowed,
        isContentCreatorOnline,
        visitProfile,
        error,
        setError,
        loading,
        setLoading,
    };
};
