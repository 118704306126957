import {ProductType} from "../../core/types/digital-product.interface";

class DigitalProductCategoryService {
    private typeToCategoryMap: Record<ProductType, string> = {
        PRIVATE_CHAT: 'Private Chat',
        PRIVATE_VOICE_CALL: 'Voice call',
        FILE: 'File'
    };

    public getCategoryName(type: ProductType): string {
        return this.typeToCategoryMap[type] || 'Unknown';
    }
}

export default new DigitalProductCategoryService();
