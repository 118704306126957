import LoginForm from "../features/auth/components/login-form";

const LoginPage = () => {
    return (
        <div className='flex h-dvh w-full items-center justify-center'>
            <LoginForm/>
        </div>
    )
}

export default LoginPage
