import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import React from "react";
import * as yup from "yup";
import useAuth from "../hooks/use-auth";
import {yupResolver} from "@hookform/resolvers/yup";
import {GetTokenResetPasswordBodyRequest} from "../types/reset-email-body.interface";
import TextFormField from "../../common/components/forms/text-form-field";
import AppButton from "../../common/components/app-button";

const schema = yup.object({
    email: yup
        .string()
        .email("Nieprawidłowy email")
        .required("Email jest wymagany"),
}).required();

interface ForgotPasswordFormProps {
    onSubmit: (emailOrUsername: string) => Promise<void>
}

const ForgotPasswordForm = ({onSubmit}: ForgotPasswordFormProps) => {
    const {loading} = useAuth();

    const methods = useForm<GetTokenResetPasswordBodyRequest>({
        resolver: yupResolver(schema),
        mode: "onBlur",
        delayError: 100,
    });

    const handleEmailSubmit = async (data :GetTokenResetPasswordBodyRequest): Promise<void> => {
        await onSubmit(data.email)
            .then()
            .catch(() => {
            })
            .finally()
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleEmailSubmit)} className='flex flex-col justify-between gap-y-5 flex-grow'>
                <TextFormField name='email' label='Email' placeholder='Write your email...'/>
                <AppButton
                    type='submit'
                    loading={loading}
                    label='Reset password'
                    className='w-full text-white text-sm py-[9px] h-fit rounded-lg font-semibold bg-primary'/>
            </form>
        </FormProvider>
    )
}

export default ForgotPasswordForm
