import React, {useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {navigation} from "./constants/menu";
import {useSidebar} from "./hooks/use-sidebar";
import useAuth from "../features/auth/hooks/use-auth";
import {UserAuthority} from "../features/auth/types/user-authority.interface";
import AppButton from "../features/common/components/app-button";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import {Base64Prefix} from "../features/common/constats/base64-prefix";
import {classNames} from "../utils/class-names";
import {ArrowLeftOnRectangleIcon} from "@heroicons/react/24/solid";
import LabelUserRole from "../features/core/components/label-user-role";

const shortLogo = require("../assets/images/main-logo-short.png");
const mainLogo = require("../assets/images/main-logo.png");

const AppMenu = () => {
    const location = useLocation();
    const {closeSidebar} = useSidebar();
    const {logout, currentUser, hasAnyAuthorities} = useAuth();
    const [isCollapsedDesktopMenu, setIsCollapsedDesktopMenu] = useState(true);

    const determineIsActive = (
        itemHref: string,
        strictActive: boolean,
        locationPathname: string,
        navigationItems: typeof navigation
    ): boolean => {
        if (strictActive) {
            return locationPathname === itemHref;
        } else {
            return locationPathname.startsWith(itemHref) && !navigationItems.some(navItem =>
                navItem.strictActive && locationPathname.startsWith(navItem.href)
            );
        }
    };

    const getUserProfile = () => {
        if (currentUser?.backgroundProfileImage) {
            return Base64Prefix + currentUser?.backgroundProfileImage;
        } else {
            return require("../assets/images/avatar.png")
        }
    }

    return (
        <div
            className={`p-4 h-full flex-grow max-w-full lg:${isCollapsedDesktopMenu ? 'min-w-[300px] w-[300px]' : 'min-w-[96px] w-[96px]'} min-w-full lg:border-r border-special-gray flex flex-col lg:flex-col-reverse overflow-x-hidden lg:overflow-x-visible`}>
            <div className={classNames(
                'flex items-center justify-between',
                !isCollapsedDesktopMenu && "flex-col !justify-center gap-3"
            )}>
                <div className={classNames(
                    `flex items-center space-x-4 p-2`,
                    !isCollapsedDesktopMenu && 'justify-center px-0'
                )}>
                    <img
                        className="h-[72px] w-[72px] lg:w-[48px] lg:h-[48px] rounded-2xl object-cover"
                        src={getUserProfile()}
                        alt="User profile"
                    />
                    {isCollapsedDesktopMenu && (
                        <div className='flex flex-col gap-1 justify-center h-full'>
                            <LabelUserRole role={currentUser?.authority}/>
                            <p className="text-[22px] lg:text-lg font-bold lg:leading-5">{currentUser?.firstname + ' ' + currentUser?.lastname}</p>
                        </div>
                    )}
                </div>
                <div className='hidden lg:block cursor-pointer text-gray hover:text-primary border border-special-gray p-2 rounded-full' onClick={logout}>
                    <ArrowLeftOnRectangleIcon className='h-6 w-6' />
                </div>
            </div>

            <ul className="mt-8 space-y-4 flex-grow ">
                {navigation
                    .filter(i => hasAnyAuthorities(i.accessRoles))
                    .map((item, index) => {
                            const isActive = determineIsActive(item.href, item.strictActive, location.pathname, navigation);

                            return (
                                <div className={classNames(
                                    'relative flex justify-between items-center',
                                    !isCollapsedDesktopMenu && "lg:justify-center"
                                )}>
                                    <NavLink
                                        key={item.name}
                                        to={item.href}
                                        end
                                        onClick={closeSidebar}
                                        className="relative flex justify-between items-center text-gray font-semibold hover:bg-gray-100 p-2 rounded-lg">
                                        {isActive && (
                                            <div className={classNames(
                                                'absolute left-[-52px] top-[-6px] bg-primary rounded-full w-[50px] h-[50px]',
                                                !isCollapsedDesktopMenu && 'left-[-64px]'
                                            )}></div>
                                        )}
                                        <div className="flex items-center space-x-2">
                                            <item.Icon className="h-6 w-6"/>
                                            {isCollapsedDesktopMenu && <span className='text-base'>{item.name}</span>}
                                        </div>
                                    </NavLink>
                                    {/*<div className={classNames(*/}
                                    {/*    'w-6 h-6 flex rounded-full bg-yellow-200 justify-center items-center text-xs font-bold mr-2',*/}
                                    {/*    !isCollapsedDesktopMenu && "absolute top-1/2 right-[-28px] translate-y-[-50%]"*/}
                                    {/*)}>*/}
                                    {/*    3*/}
                                    {/*</div>*/}
                                </div>
                            )
                        }
                    )}
            </ul>

            <div className="p-2 block lg:hidden" onClick={logout}>
                <AppButton label='Log out'
                           className='w-full border-gray border text-gray hover:text-primary py-2 !rounded-2xl'/>
            </div>

            <div className="px-2 hidden lg:flex items-center justify-between relative w-full">
                <div className={classNames(
                    'flex justify-center',
                    !isCollapsedDesktopMenu && "w-full"
                )}>
                    <img
                        alt='logo yurs'
                        className={classNames(
                            'h-14', !isCollapsedDesktopMenu && "ml-1"
                        )}
                        src={isCollapsedDesktopMenu ? mainLogo : shortLogo}/>
                </div>
                {isCollapsedDesktopMenu ? (
                    <ChevronLeftIcon
                        onClick={() => setIsCollapsedDesktopMenu(prev => !prev)}
                        className='border border-special-gray rounded-full min-w-8 w-8 h-8 p-1 text-gray cursor-pointer hover:text-primary'/>
                ) : (
                    <ChevronRightIcon
                        onClick={() => setIsCollapsedDesktopMenu(prev => !prev)}
                        className={classNames(
                            'border border-special-gray rounded-full min-w-8 w-8 h-8 p-1 text-gray cursor-pointer hover:text-primary',
                            !isCollapsedDesktopMenu && 'absolute top-1/2 right-[-32px] bg-white translate-y-[-50%]'
                        )}/>
                )}
            </div>
        </div>
    );
}

export default AppMenu;
