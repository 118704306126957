import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../../utils/class-names";
import SkeletonFormField from "./skeleton-form-field";

interface SelectFormFieldProps {
    name: string;
    label: string;
    options?: any[]; // Opcjonalne, ponieważ może być pobierane z promisa
    fetchOptions?: () => Promise<any[]>; // Funkcja, która zwraca promisa
    className?: string;
    valueKey?: string;
    labelKey?: string;
    parentWrapperRef?: RefObject<HTMLElement>;
    onChange?: (value: any) => void;
    isLoading?: boolean;
    isRequired?: boolean;
}

const SelectFormField: React.FC<SelectFormFieldProps> = (props: SelectFormFieldProps) => {
    const { register, setValue, watch, formState: { errors, isSubmitting } } = useFormContext();
    const [dropUp, setDropUp] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(!!props.isLoading); // Używamy lokalnego stanu do obsługi loadingu
    const [options, setOptions] = useState<any[]>(props.options || []); // Ustawiamy opcje na podstawie propsa lub jako pustą tablicę
    const [selectedOption, setSelectedOption] = useState<any | null>(null); // Przechowywanie wybranej opcji
    const [isOpen, setIsOpen] = useState(false); // Dodajemy stan do śledzenia otwarcia listy
    const selectRef = useRef<HTMLDivElement>(null);
    const {
        name,
        label,
        className = '',
        valueKey = 'value',
        labelKey = 'label',
        isRequired = false,
        parentWrapperRef,
        onChange,
        fetchOptions,
        ...rest
    } = props;

    const defaultValue = watch(name) || '';

    useEffect(() => {
        if (fetchOptions) {
            setIsLoading(true);
            fetchOptions().then((fetchedOptions) => {
                setOptions(fetchedOptions);
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        } else if (props.options) {
            setOptions(props.options);
        }
    }, [fetchOptions, defaultValue, props.options, valueKey]);

    const checkDropUp = () => {
        if (parentWrapperRef?.current && selectRef.current) {
            const optionHeight: number = 42;
            const optionsHeight: number = optionHeight * options.length;
            const parentRect: DOMRect = parentWrapperRef.current.getBoundingClientRect();
            const selectRect: DOMRect = selectRef.current.getBoundingClientRect();
            const spaceBelow: number = parentRect.bottom - selectRect.bottom;
            const shouldDropUp: boolean = spaceBelow - optionsHeight < 0;
            setDropUp(shouldDropUp);
        }
    };

    const handleChange = (option: any): void => {
        setSelectedOption(option);
        setValue(name, option[valueKey]);
        onChange && onChange(option);
        setIsOpen(false);
    };

    useEffect(() => {
        if (selectedOption && selectedOption[valueKey] !== defaultValue) {
            setValue(name, selectedOption[valueKey]);
        }
    }, [selectedOption, setValue, name, valueKey, defaultValue]);

    return (
        <div className="mb-4 relative w-full h-fit" ref={selectRef}>
            <label htmlFor={name} className="absolute block text-sm font-medium top-2 left-[14px]">
                {label}
                {isRequired && <span className='text-red-500'>*</span>}
            </label>

            {!isLoading ? (
                <Listbox value={selectedOption} onChange={handleChange} disabled={isSubmitting}>
                    <Listbox.Button
                        onClick={() => {
                            checkDropUp();
                            setIsOpen(!isOpen); // Przełączamy stan otwarcia listy
                        }}
                        className={classNames(
                            'relative border-2 rounded-xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2',
                            errors[name] ? 'border-red-500 focus:ring-red-500' : isOpen ? 'border-primary focus:ring-primary' : 'border-gray-light focus:ring-primary',
                            className
                        )}
                    >
                        <span className={classNames(
                            "block truncate text-left text-[#9ca3afe8]",
                            selectedOption && '!text-black'
                        )}>{selectedOption?.[labelKey] || 'Select an option'}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                    <Listbox.Options
                        className={classNames(
                            dropUp ? 'bottom-full mb-2 ' : 'top-full mt-1',
                            'absolute w-full py-1 mt-1 bg-white border-2 rounded-xl focus:outline-none z-10',
                            isOpen ? 'border-primary ring-primary' : 'border-gray-light'
                        )}
                    >
                        {options.map((option, index) => (
                            <Listbox.Option
                                key={index}
                                value={option}
                                className={({ active }) => classNames(
                                    'cursor-pointer select-none relative py-2 mx-4',
                                    active ? 'bg-primary-600 text-primary' : '',
                                    options.length > index && 'border-b border-gray-light'
                                )}
                            >
                                {({ selected }) => (
                                    <span className={classNames(
                                        'block truncate',
                                        selected ? 'font-medium' : 'font-normal'
                                    )}>
                                        {option[labelKey]}
                                    </span>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Listbox>
            ) : (
                <SkeletonFormField />
            )}

            <input type="hidden" {...register(name)} value={selectedOption?.[valueKey]} />

            {errors[name] && <p className="text-red-500 text-xs mt-1">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default SelectFormField;
