import {UserAuthority} from "../../auth/types/user-authority.interface";
import React from "react";

interface LabelUserRoleProps {
    role?: UserAuthority;
}

const LabelUserRole = (props: LabelUserRoleProps) => {
    const {role} = props

    return (
        <>
            {role === "ROLE_CONTENT_CREATOR" && (
                <span
                    className="text-[10px] font-semibold bg-primary-pastel text-primary p-1 leading-none uppercase rounded-lg tracking-widest w-fit">
                    CREATOR
                </span>
            )}
            {role === "ROLE_FAN" && (
                <span
                    className="text-[10px] font-semibold bg-primary-pastel text-primary p-1 leading-none uppercase rounded-lg tracking-widest w-fit">
                    FAN
                </span>
            )}
        </>
    )
}

export default LabelUserRole
