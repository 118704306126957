import AppWrapper from "../layout/components/app-wrapper";
import AppHeader from "../layout/app-header";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import React from "react";

const ProfilePage = () => {
    return (
        <AppWrapper>
            <AppHeader/>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem]'>
                Private profile
            </AppContentWrapper>
        </AppWrapper>
    )
}

export default ProfilePage
