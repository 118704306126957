import React, {ReactNode, useEffect} from "react";
import {useModal} from "../hooks/use-modal";
import {ModalIds} from "../types/modal-ids.interface";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {animated, useTransition} from "@react-spring/web";

interface AppModalProps {
    id: ModalIds;
    children?: ReactNode;
    disableScroll?: boolean;
}

const AppModal: React.FC<AppModalProps> = (props: AppModalProps) => {
    const {closeModal, isOpenModal} = useModal();
    const {id, children, disableScroll = false} = props;
    const isModalOpen = isOpenModal(id);

    const transitionsBackdrop = useTransition(isModalOpen, {
        from: {opacity: 0},
        enter: {opacity: 0.2},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    const transitionsModal = useTransition(isModalOpen, {
        from: {opacity: 0, transform: "scale(0.9)"},
        enter: {opacity: 1, transform: "scale(1)"},
        leave: {opacity: 0, transform: "scale(0.9)"},
        config: {tension: 100, friction: 20},
    });

    useEffect(() => {
        const preventScroll = (event: Event) => {
            event.preventDefault();
        };

        if (isModalOpen && disableScroll) {
            window.addEventListener("wheel", preventScroll, {passive: false});
            window.addEventListener("touchmove", preventScroll, {passive: false});
        } else {
            window.removeEventListener("wheel", preventScroll);
            window.removeEventListener("touchmove", preventScroll);
        }

        return () => {
            window.removeEventListener("wheel", preventScroll);
            window.removeEventListener("touchmove", preventScroll);
        };
    }, [isModalOpen, disableScroll]);

    if (!isModalOpen) return null;

    return (
        <>
            {transitionsBackdrop(
                (styles, item) =>
                    item && (
                        <animated.div style={styles} className="fixed inset-0 bg-gray z-10"></animated.div>
                    )
            )}
            {transitionsModal(
                (styles, item) =>
                    item && (
                        <animated.div style={styles} className="z-[11] fixed inset-0">
                            <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] flex gap-3
                            lg:max-w-lg md:max-w-md sm:max-w-md w-[100%] px-3 sm:w-fit flex-row justify-end flex-wrap sm:flex-nowrap sm:flex-row-reverse">
                                <div
                                    onClick={() => closeModal(id)}
                                    className="bg-white rounded-full h-fit w-fit p-2 cursor-pointer text-gray"
                                >
                                    <XMarkIcon className="w-5 h-5"/>
                                </div>
                                <div
                                    className="h-fit flex-grow w-full sm:max-w-[calc(88%)] sm:min-w-fit min-h-40 bg-white rounded-2xl p-5 drop-shadow-2xl">
                                    {children}
                                </div>
                            </div>
                        </animated.div>
                    )
            )}
        </>
    );
};

export default AppModal;
