import React, {ButtonHTMLAttributes, RefObject, useState} from 'react';
import SpinnerLoader from "./spinner-loader";
import {classNames} from "../../../utils/class-names";

interface ButtonProps extends ButtonHTMLAttributes<any> {
    label: string;
    onClick?: () => void | Promise<void>;
    className?: string;
    loadingClassName?: string;
    disabledClassName?: string;
    loaderColor?: string;
    disabled?: boolean;
    loading?: boolean;
    ref?: RefObject<HTMLButtonElement>
}

const AppButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        label,
        onClick,
        className = '',
        loadingClassName = '',
        disabledClassName = '',
        loaderColor = 'white',
        disabled = false,
        type,
        loading = false,
        children,
        ref
    } = props;

    const handleClick = async () => {
        if (disabled || isLoading) return;
        setIsLoading(true);
        try {
            if (onClick) await onClick();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const buttonClass: string = `${className} ${isLoading ? loadingClassName : ''} ${disabled || isLoading ? disabledClassName : ''}`;

    return (
        <button ref={ref}
                type={type}
                className={classNames(
                    'py-2 px-4 border rounded-xl ' + buttonClass,
                    disabled && "!bg-gray-light !text-gray border-gray-light cursor-not-allowed"
                )}
                onClick={handleClick}
                disabled={disabled || isLoading || loading}
        >
            <div className="flex justify-center items-center gap-2">
                {(isLoading || loading) && <SpinnerLoader color={loaderColor}/>}
                {label && <span>{label}</span>}
                {children && <span>{children}</span>}
            </div>
        </button>
    );
};

export default AppButton;
