import React from 'react';
import './App.css';
import AppRoutes from "./app-routes";
import {BrowserRouter as Router} from "react-router-dom";
import NotificationPrompt from "./features/common/components/notification/notification-prompt";
import {AuthProvider} from "./features/auth/contexts/auth-context";
import {RedirectProvider} from "./features/auth/contexts/redirect-context";
import LoginRequiredModal from "./features/auth/components/login-required-modal";
import {ModalProvider} from "./features/common/contexts/modal-context";
import FollowRequiredModal from "./features/common/components/follow-required-modal";

function App() {
    return (
        <Router>
            <AuthProvider>
                <RedirectProvider>
                    <ModalProvider>
                        <AppRoutes/>
                        <LoginRequiredModal/>
                        <FollowRequiredModal/>
                    </ModalProvider>
                    <NotificationPrompt/>
                </RedirectProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
