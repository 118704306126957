import {ReactNode} from "react";

type AppContentWrapperProps = {
    children: ReactNode;
    className?: string;
};

const AppContentWrapper = (props: AppContentWrapperProps) => {
    const {children, className} = props

    return (
        <div className={'mx-auto max-w-[800px] relative w-full h-full z-[1] ' +className}>
            {children}
        </div>
    )
}

export default AppContentWrapper
