import React from 'react';
import {Base64Prefix} from "../../common/constats/base64-prefix";
import LabelUserRole from "../../core/components/label-user-role";
import {UserAuthority} from "../../auth/types/user-authority.interface";

interface ProfileImageProps {
    avatar: string | ArrayBuffer;
    showRole?: boolean;
    role: UserAuthority;
}

const ProfileImage: React.FC<ProfileImageProps> = (props: ProfileImageProps) => {
    const {
        avatar,
        showRole = true,
        role
    } = props

    return (
        <div className="w-32 h-32 relative">
            <img src={`${Base64Prefix}${avatar}`} alt="Avatar"
                 className="w-full h-full object-cover rounded-3xl border-[5px] border-white "/>
            {showRole && (
                <div className='relative bottom-[20px] left-1/2 translate-x-[-50%] h-fit w-fit'>
                    <LabelUserRole role={role}/>
                </div>
            )}
        </div>
    );
};

export default ProfileImage;
